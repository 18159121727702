import React, { useEffect, useState } from 'react';
import fetcher from '../../utils/fetcher';
import { AppBar, Box, Tab, Tabs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { PageUrls } from '../../utils/constants';

const BrokerLoginDetails = () => {
    const [userBrokers, setUserBrokers] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const fetchUserBrokers = async () => {
        try {
            const res = await fetcher.get(`/user-api/user-broker-authenticated`);
            if (res?.status === 200) {
                setUserBrokers(res.response);
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchUserBrokers();
    }, []);

    return (
        <Box>
            {userBrokers && userBrokers.length > 0 ? (
                <>
                <AppBar position="static">
                    <Tabs value={selectedTab} onChange={handleChange}>
                    {userBrokers.map((userBroker, index) => (
                        <Tab key={userBroker.broker.id} label={userBroker.broker.name} />
                    ))}
                    </Tabs>
                </AppBar>
                {userBrokers.map((userBroker, index) => (
                    <TabPanel key={userBroker.userBroker.id} value={selectedTab} index={index}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1, marginRight: '20px' }}>
                        <Typography>Name: {userBroker.userBroker.name}</Typography>
                        <Typography>Email: {userBroker.userBroker.email}</Typography>
                        <Typography>Mobile: {userBroker.userBroker.mobile}</Typography>
                        <Typography>App Id: {userBroker.userBroker.app_id}</Typography>
                        <Typography>App Key: {userBroker.userBroker.app_key}</Typography>
                        <Typography>A/c Balance: {userBroker.userFund[0].equityAmount}</Typography>
                        <Typography>Available Balance: {userBroker.userFund[9].equityAmount}</Typography>
                        </div>
                    </div>
                    </TabPanel>
                ))}
                </>
            ) : (
                <Box>
                    <Typography variant="body1">
                        Broker A/C is not linked. Please complete{' '}
                        <Link to={PageUrls.BROKER_LOGIN} style={{ color: 'inherit', textDecoration: 'underline' }}>
                            Broker Login
                        </Link>
                    </Typography>
                </Box>
            )}
            </Box>
    );
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};


export default BrokerLoginDetails;
