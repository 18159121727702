export const PageUrls = {
    UNAUTHORIZED: '/unauthorized',
    INDEX: '/',
    LOGIN: '/login',
    TICKET_DETAIL: '/ticket/ticket-detail/:id',
    BROKER_LOGIN: '/broker-login',
    BROKER_DETAILS: '/broker-details',
    BROKER_LOGIN_SUCCESS: '/broker-login-success/:brokerId',
    STRATEGY: '/strategy',
    SETTINGS: '/settings',
    STOCK_LIVE: '/stock-live',
    PAYMENT_CHECKOUT: '/payment-checkout',
    OPTION_CHAIN: '/option-chain',
    NIFTY_INDICES: '/nifty-indices',
    OPTION_CHAIN_INDICATOR: '/option-chain-indicator/:strikePrice',
    USER_TRANSACTION: '/user-transaction',
    HOME_WORK: '/homework'
};

export const PageRouteRegx = {
    TICKET_DETAIL: '/ticket/ticket-detail/[0-9]',
    BROKER_LOGIN_SUCCESS: /^\/broker-login-success\/\d+\/?$/,
    OPTION_CHAIN_INDICATOR: /^\/option-chain-indicator\/\d+\/?$/
};

export const AUTHORITY = {
    USER: 'USER',
    ADMIN: 'ADMIN'
};

export const AUTHORITY_ROUT_MAP = [
    { route_regx: PageUrls.STRATEGY, authority: [AUTHORITY.ADMIN] },
    { route_regx: PageUrls.OPTION_CHAIN, authority: [AUTHORITY.ADMIN] },
    { route_regx: PageUrls.BROKER_LOGIN, authority: [AUTHORITY.ADMIN, AUTHORITY.USER] },
    { route_regx: PageUrls.BROKER_DETAILS, authority: [AUTHORITY.ADMIN, AUTHORITY.USER] },
    { route_regx: PageRouteRegx.BROKER_LOGIN_SUCCESS, authority: [AUTHORITY.ADMIN, AUTHORITY.USER] },
    { route_regx: PageRouteRegx.OPTION_CHAIN_INDICATOR, authority: [AUTHORITY.ADMIN] },
    { route_regx: PageUrls.USER_TRANSACTION, authority: [AUTHORITY.ADMIN] },
    { route_regx: PageUrls.NIFTY_INDICES, authority: [AUTHORITY.ADMIN] },
    { route_regx: PageUrls.HOME_WORK, authority: [AUTHORITY.ADMIN] },
];
