import React, { useEffect, useState } from 'react';
import fetcher from "../../utils/fetcher";
import { Container, Table, TableBody, TableCell, TableHead, TableRow, Paper, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';

const OptionChainIndicator = () => {
    const { strikePrice } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const res = await fetcher.get(`/admin-api/option-chain-indicator/${strikePrice}`);
            setData(res.response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();

        const interval = setInterval(() => {
            setLoading(true);
            fetchData();
        }, 300000); // 5 minutes

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    if (data.length === 0) {
        return <div>No records found</div>;
    }

    return (
        <Container>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Strike Price</TableCell>
                            <TableCell>PCR</TableCell>
                            <TableCell>Diff In OI</TableCell>
                            <TableCell>Volume</TableCell>
                            <TableCell>Direction Change</TableCell>
                            <TableCell>Percentage Change</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.time}</TableCell>
                                <TableCell>{row.strike_price}</TableCell>
                                <TableCell>{row.pcr}</TableCell>
                                <TableCell>{row.diff_in_oi}</TableCell>
                                <TableCell>{row.volume}</TableCell>
                                <TableCell>{row.direction_change}</TableCell>
                                <TableCell>{row.change_percentage}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Container>
    );
};

export default OptionChainIndicator;
