import React from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { canAccessTheRouteWithUserAuthorities, isAuthenticated, isRestrictedRoutWithAuthority, setRedirectLoginCallback } from "../utils/authGuard";
import Layout from "../components/layout/Layout";  // Import the Layout HOC
import Login from "../pages/login/Login";
import Index from "../pages/Index";
import { NoMatch } from "../pages/NoMatch";
import { PageUrls } from "../utils/constants";
import BrokerLogin from "../pages/user/BrokerLogin";
import Settings from "../pages/settings/Settings";
import Strategy from "../pages/strategy/Strategy";
import BrokerLoginSuccess from "../pages/user/BrokerLoginSuccess";
import StockLivePrice from "../pages/strategy/StockLivePrice";
import BrokerLoginDetails from "../pages/user/BrokerLoginDetails";
import Checkout from "../pages/payment/Checkout";
import OptionChain from "../pages/market/OptionChain";
import OptionChainIndicator from "../pages/market/OptionChainIndicator";
import UserTrades from "../pages/transaction/UserTrades";
import NiftyIndices from "../pages/market/NiftyIndices";
import HomeWork from "../pages/market/HomeWork";


function PrivateRoute({ element }) {
  const location = useLocation();
  if (!isAuthenticated()) {
    return  <Navigate to={PageUrls.LOGIN} />;
  }
  if (isRestrictedRoutWithAuthority(location.pathname) && !canAccessTheRouteWithUserAuthorities(location.pathname)) {
    return <Navigate to={PageUrls.UNAUTHORIZED} />
  }
  return element;
}

const Routers = () => {
    const navigate = useNavigate();
    setRedirectLoginCallback(() => {
      navigate(PageUrls.LOGIN);
    });
    
    return(
        <Routes>
          <Route path={PageUrls.LOGIN} element={<Login />}/>
          <Route path={PageUrls.INDEX} element={<Index />}/>
          <Route path={PageUrls.BROKER_LOGIN} element={<PrivateRoute element={<Layout> <BrokerLogin /> </Layout>} />} />
          <Route path={PageUrls.BROKER_DETAILS} element={<PrivateRoute element={<Layout> <BrokerLoginDetails /> </Layout>} />} />
          <Route path={PageUrls.BROKER_LOGIN_SUCCESS} element={<PrivateRoute element={<Layout> <BrokerLoginSuccess /> </Layout>} />} />
          <Route path={PageUrls.STRATEGY} element={<PrivateRoute element={<Layout> <Strategy /> </Layout>} />} />
          <Route path={PageUrls.SETTINGS} element={<PrivateRoute element={<Layout> <Settings /> </Layout>} />} />
          <Route path={PageUrls.UNAUTHORIZED} element={<Layout> <NoMatch /> </Layout>} />
          <Route path={PageUrls.STOCK_LIVE} element={<PrivateRoute element={<Layout> <StockLivePrice /> </Layout>} />} />
          <Route path={PageUrls.PAYMENT_CHECKOUT} element={<Checkout />} />
          <Route path={PageUrls.OPTION_CHAIN} element={<PrivateRoute element={<Layout> <OptionChain /> </Layout>} />} />
          <Route path={PageUrls.OPTION_CHAIN_INDICATOR} element={<PrivateRoute element={<Layout> <OptionChainIndicator /> </Layout>} />} />
          <Route path={PageUrls.USER_TRANSACTION} element={<PrivateRoute element={<Layout> <UserTrades /> </Layout>} />} />
          <Route path={PageUrls.NIFTY_INDICES} element={<PrivateRoute element={<Layout> <NiftyIndices /> </Layout>} />} />
          <Route path={PageUrls.HOME_WORK} element={<PrivateRoute element={<Layout> <HomeWork /> </Layout>} />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
    )
}

export default Routers;