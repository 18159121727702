import React, { useState, useEffect } from "react";
import { AppBar, Tabs, Tab, Box, Typography, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, Switch } from "@mui/material";
import fetcher from "../../utils/fetcher";
import { Field, Form, Formik } from "formik";
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import StockLivePrice from "./StockLivePrice";
import dayjs from 'dayjs';

const isBefore915AM = () => {
  const now = dayjs();
  const nineFifteenAM = dayjs().set('hour', 9).set('minute', 15).set('second', 0);
  return now.isBefore(nineFifteenAM);
};

const Strategy = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [groupedStrategies, setGroupedStrategies] = useState([]);
  const [strategyGroups, setStrategyGroups] = useState([]);
  const [switchStates, setSwitchStates] = useState({});

  const strategyGroupNames = {
    1: 'Option',
    2: 'Option Selling',
    3: 'Stock Option'
  };

  const fetchStrategy = async () => {
    try {
      const res = await fetcher.get(`/admin-api/strategy`);
      const groupedStrategies = groupStrategiesByGroup(res.response);
      setGroupedStrategies(groupedStrategies);
      setStrategyGroups(Object.keys(groupedStrategies));
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateIndicators = async (values) => {
    try {
      await fetcher.post(`/admin-api/update-strategy-indicator`, values);
      console.log(values);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateTradeSettings = async (values) => {
    try {
      await fetcher.post(`/admin-api/update-strategy-trade-setting`, values);
    } catch (err) {
      console.log(err);
    }
  };

  const groupStrategiesByGroup = (strategies) => {
    return strategies.reduce((acc, strategy) => {
      const { strategy_group } = strategy;
      if (!acc[strategy_group]) {
        acc[strategy_group] = [];
      }
      acc[strategy_group].push(strategy);
      return acc;
    }, {});
  };

  const handleStrategyOrderExecute = async (strategy_id) => {
    const res = await fetcher.post('/admin-api/strategy-order-execute', {strategy_id: strategy_id});
    console.log(res);
  };

  const handleStrategyActivateOrder = async (strategyId, checked) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [strategyId]: checked,
    }));
    await fetcher.post('/admin-api/strategy-activate-order', {strategy_id: strategyId, order_status: !checked});
  };

  useEffect(() => {
    fetchStrategy();
  }, []);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <div>
        <AppBar position="static">
          <Tabs value={selectedTab} onChange={handleChange}>
            {strategyGroups.map((group, index) => (
              <Tab key={group} label={strategyGroupNames[group] || `Strategy Group ${group}`} />
            ))}
          </Tabs>
        </AppBar>
        
        {strategyGroups.map((group, groupIndex) => (
          <TabPanel key={group} value={selectedTab} index={groupIndex}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              {groupedStrategies[group].map((strategy) => (
                <Card key={strategy.strategy_id} style={{ marginBottom: '20px', display: 'flex' }}>
                  {/* Left side: Indicators and Trade Settings */}
                  <div style={{ flex: 0.6, paddingRight: '20px' }}>
                  <CardHeader
                      title={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="h6">{strategy.name}</Typography>
                          <Switch
                            checked={switchStates[strategy.strategy_id] ?? strategy.strategy_order_track}
                            onChange={(e) => handleStrategyActivateOrder(strategy.strategy_id, e.target.checked)}
                            color="primary"
                            style={{ marginLeft: '10px' }}
                            disabled={!isBefore915AM()} 
                          />
                        </div>
                      }
                    />
                    <CardContent>
                      {/* Strategy Indicators */}
                      {strategy.indicators.length > 0 && (
                        <div>
                          <Typography variant="h6">Buy Indicators:</Typography>
                          <Formik
                            initialValues={{
                              indicators: strategy.indicators.map((indicator) => ({
                                ...indicator,
                              })),
                            }}
                            onSubmit={(values) => {
                              handleUpdateIndicators(values);
                            }}
                          >
                            {({ values, handleChange }) => (
                              <Form>
                                {values.indicators.map((indicator, index) => (
                                  <div key={indicator.id}>
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name={`indicators.${index}.status`}
                                        checked={indicator.status}
                                      />
                                      {indicator.name}
                                    </label>
                                    <Field
                                      type="number" // Use "text" if indicator.value is a string
                                      name={`indicators.${index}.value`}
                                      value={indicator.value}
                                      onChange={handleChange}
                                    />
                                  </div>
                                ))}
                                <button type="submit">Submit Indicators</button>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      )}

                      {/* Trade Settings */}
                      {strategy.trade_setting && (
                        <div style={{ marginTop: '20px' }}>
                          <Typography variant="h6">Trade Settings (%):</Typography>
                          <Formik
                            initialValues={{
                              strategy_id: strategy.strategy_id,
                              fund_limit: strategy.trade_setting.fund_limit,
                              stop_loss: strategy.trade_setting.stop_loss,
                              target: strategy.trade_setting.target,
                              trailing: strategy.trade_setting.trailing,
                              quantity: strategy.trade_setting.quantity,
                            }}
                            onSubmit={(values) => {
                              handleUpdateTradeSettings(values);
                            }}
                          >
                            {({ values, handleChange }) => (
                              <Form style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <div>
                                  <label>Fund Limit:</label>
                                  <Field
                                    type="number"
                                    name="fund_limit"
                                    value={values.fund_limit}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  <label>Stop Loss:</label>
                                  <Field
                                    type="number"
                                    name="stop_loss"
                                    value={values.stop_loss}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  <label>Target:</label>
                                  <Field
                                    type="number"
                                    name="target"
                                    value={values.target}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  <label>Trailing:</label>
                                  <Field
                                    type="number"
                                    name="trailing"
                                    value={values.trailing}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  <label>Quantity:</label>
                                  <Field
                                    type="number"
                                    name="quantity"
                                    value={values.quantity}
                                    onChange={handleChange}
                                  />
                                </div>
                                <button type="submit" style={{ alignSelf: 'flex-end' }}>
                                  Submit Trade Settings
                                </button>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      )}
                      {strategy.strategy_id === 6 && (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleStrategyOrderExecute(strategy.strategy_id)}
                          style={{ marginTop: '16px' }}
                        >
                          Strategy Order Execute
                        </Button>
                      )}
                    </CardContent>
                  </div>

                  {/* Right side: Strategy Symbols */}
                  <div style={{ flex: 0.4, borderLeft: '1px solid #ddd', paddingLeft: '20px' }}>
                    <Typography variant="h6">Strategy Symbols:</Typography>
                    {strategy.strategy_symbols.map((symbol) => (
                      <div key={symbol.id}>
                        <Typography variant="body2">
                          {symbol.symbol} - Expiry: {symbol.expiry}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </Card>
              ))}
            </div>
          </TabPanel>
        ))}

      </div>
      {/* <div>
        <StockLivePrice />
      </div> */}
    </>
  );
  
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default Strategy;
