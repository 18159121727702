import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageUrls } from '../utils/constants';

const Index = () => {

  const navigate = useNavigate();

  const navigateTo = () => {
    navigate(PageUrls.LOGIN);
  };

  useEffect(() => {
    navigateTo();
  }, [])

  return(
    <></>
  );

};

export default Index;
