import React, { useEffect, useState } from 'react';
import style from './Sidebar.module.scss'; 
import { NavLink, useNavigate } from 'react-router-dom';
import {AUTHORITY, PageUrls } from "../../utils/constants";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import { IconButton } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import LoginIcon from '@mui/icons-material/Login';
import InfoIcon from '@mui/icons-material/Info';
import BarChartIcon from '@mui/icons-material/BarChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import NavItem from './NavItem';
import { getAuthUser, userAuthority } from '../../utils/authGuard';

const Sidebar = ({closeEvent}) => {
  const navigate = useNavigate();
  const authority = userAuthority();
  const [navs, setNavs] = useState([]);
  
  useEffect(() => {
    if(authority.includes(AUTHORITY.ADMIN)){
      setNavs(prevNavs => [...prevNavs,
        { url: PageUrls.HOME_WORK, title: 'Home Work', icon: <HomeWorkIcon /> },
        { url: PageUrls.STRATEGY, title: 'Strategies', icon: <BarChartIcon /> },
        { url: PageUrls.OPTION_CHAIN, title: 'Option Chain', icon: <TimelineIcon /> },
        { url: PageUrls.NIFTY_INDICES, title: 'Nifty Indices', icon: <AccountBalanceIcon /> },
        { url: PageUrls.USER_TRANSACTION, title: 'User Transaction', icon: <MonetizationOnIcon /> }

      ]);
    }
    setNavs(prevNavs => [...prevNavs,
      { url: PageUrls.BROKER_LOGIN, title: 'Broker Login', icon: <LoginIcon /> },
      { url: PageUrls.BROKER_DETAILS, title: 'Broker Details', icon: <InfoIcon /> }
    ]);
  },[]);

  const mWebCloseEvent = () => {
    if(closeEvent){
      closeEvent();
    }else{
      return false;
    }
  }

  const handleLogout = () => {
    localStorage.removeItem('auth');
    navigate('/login');
  };
  
  return (
    <>
      <div className={style.sidebarNavigation}>
        <div className='d-lg-none mb-2'>
          <IconButton onClick={closeEvent}>
            <MenuOpenIcon />
          </IconButton>
        </div>
        <ul>
          {navs.map((item, index) =>
            <NavItem {...item} key={index} mWebCloseEvent={mWebCloseEvent} />
          )}
        </ul>

        <div className={style.sidebarBottom}>
          <div className={style.userInfo}> 
            <div className={style.userInfoTxt}>
              <strong>{getAuthUser()?.username}</strong>
              <span>{getAuthUser()?.roleName}</span>
            </div> 
          </div>
          <ul>
            <li>
              <NavLink to="/settings" onClick={mWebCloseEvent}>
                <SettingsIcon />
                Settings
              </NavLink>
            </li>
            <li>
              <NavLink to="/" className={style.logoutLink} onClick={handleLogout}>
                <LogoutIcon />
                Log out
              </NavLink>
            </li>
          </ul> 
        </div>
      </div>
    </>
    
  );
}

export default Sidebar;