import React, { useEffect, useState } from 'react';
import fetcher from "../../utils/fetcher";
import { Container, Table, TableBody, TableCell, TableHead, TableRow, Paper, CircularProgress, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const NiftyIndices = () => {
    const [indicesData, setIndicesData] = useState([]);
    const [stocksData, setStocksData] = useState([]);
    const [selectedSector, setSelectedSector] = useState('');
    const [loading, setLoading] = useState(true);

    const fetchIndices = async () => {
        try {
            const res = await fetcher.get(`/admin-api/nifty/Indices`);
            setIndicesData(res.response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchStocks = async (fullSectorName) => {
        try {
            setLoading(true);
            setStocksData([]);
            const sector = fullSectorName.split(' ')[1];
            setSelectedSector(sector);
            const res = await fetcher.get(`/admin-api/nifty/${sector}`);
            setStocksData(res.response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
      };

    useEffect(() => {
        fetchIndices();

        const interval = setInterval(() => {
            setLoading(true);
            fetchIndices();
        }, 300000); // 5 minutes

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    if (loading && indicesData.length === 0) {
        return <CircularProgress />;
    }

    if (indicesData.length === 0) {
        return <div>No records found</div>;
    }

    return (
        <Container>
            <Typography variant="h4" gutterBottom>Indices</Typography>
            <Paper>
                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell>Symbol</TableCell>
                    <TableCell>Current</TableCell>
                    <TableCell>Change</TableCell>
                    <TableCell>Open</TableCell>
                    <TableCell>High</TableCell>
                    <TableCell>Low</TableCell>
                    <TableCell>Prev. Close</TableCell>
                    <TableCell>Direction</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {indicesData.map((row) => (
                    <TableRow key={row.id} onClick={() => fetchStocks(row.name)}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.current_price}</TableCell>
                        <TableCell>{row.change_percentage}</TableCell>
                        <TableCell>{row.open}</TableCell>
                        <TableCell>{row.high}</TableCell>
                        <TableCell>{row.low}</TableCell>
                        <TableCell>{row.prev_close}</TableCell>
                        <TableCell>{row.direction}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </Paper>

            {selectedSector && (
                <>
                <Typography variant="h4" gutterBottom>{selectedSector} Stocks</Typography>
                {loading ? (
                    <CircularProgress /> // Show loader while loading
                ) : (
                    <Paper>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell>Symbol</TableCell>
                            <TableCell>Current</TableCell>
                            <TableCell>Change</TableCell>
                            <TableCell>Open</TableCell>
                            <TableCell>High</TableCell>
                            <TableCell>Low</TableCell>
                            <TableCell>Prev. Close</TableCell>
                            <TableCell>Direction</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {stocksData.length > 0 ? (
                            stocksData.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.current_price}</TableCell>
                                <TableCell>{row.change_percentage}</TableCell>
                                <TableCell>{row.open}</TableCell>
                                <TableCell>{row.high}</TableCell>
                                <TableCell>{row.low}</TableCell>
                                <TableCell>{row.prev_close}</TableCell>
                                <TableCell>{row.direction}</TableCell>
                            </TableRow>
                            ))
                        ) : (
                            <TableRow>
                            <TableCell colSpan={8} align="center">No record found</TableCell>
                            </TableRow>
                        )}
                        </TableBody>
                    </Table>
                    </Paper>
                )}
                </>
            )}
        </Container>
    );
};

export default NiftyIndices;