import React, { useEffect, useState } from 'react';
import style from './BrokerLogin.module.scss';

import fetcher from '../../utils/fetcher'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SnackBar from '../../components/SnackBar';
import { MenuItem, Select } from '@mui/material';

const BrokerLogin = () => {

    const [snackbar, setSnackbar] = useState({
        show: false,
        status: "",
        message: "",
    });
    const toggleSnackbar = (value) => {
        setSnackbar(value);
    };

    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        appId: '',
        appKey: '',
        brokerId: ''
    });

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        mobile: Yup.string()
            .required('Mobile is required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email address is required'),
        appId: Yup.string()
            .required('Client id is required'),
        appKey: Yup.string()
            .required('App key is required'),
        brokerId: Yup.string()
            .required('Please select a broker')
    });

    const handleSubmit = async (values) => {
        try {
            const res = await fetcher.post(`/user-api/user-broker-login`, values);
            if (res?.status === 200) {
                window.open(res.response.token_url);
            }
        } catch (err) {
            console.log(err);
        }
    }

    

    const formik = useFormik({
        initialValues: formData,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    const handleBrokerChange = async (event) => {
        try {
            formik.handleChange(event);
            const res = await fetcher.get(`/user-api/user-broker/${event.target.value}`);
            if (res?.status === 200) {
                formik.setValues({
                    ...formik.values,
                    ...res.response
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
    }, [])


    return (
        <>
            <SnackBar {...snackbar} onClose={toggleSnackbar} />
            <h3>Connect Your Demat Account</h3>
            <div className='form-group'>
                <label className='label-control'>Select Broker<span>*</span></label>
                <Select
                    name="brokerId"
                    value={formik.values.brokerId}
                    onChange={handleBrokerChange}
                    className="form-control"
                >
                    <MenuItem value="">Select Broker</MenuItem>
                    <MenuItem value="1">Flyer</MenuItem>
                </Select>
                {formik.touched.brokerId && formik.errors.brokerId && (
                    <div className='errorMsg'>{formik.errors.brokerId}</div>
                )}
            </div>  
            <div className='form-group'>
                <label className='label-control'>Name<span>*</span></label>
                <input
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    className="form-control"
                    disabled={!formik.values.brokerId} 
                />
                {formik.touched.name && formik.errors.name && (
                    <div className='errorMsg'>{formik.errors.name}</div>
                )}
            </div>
            <div className='form-group'>
                <label className='label-control'>Mobile<span>*</span></label>
                <input
                    name="mobile"
                    onChange={formik.handleChange}
                    value={formik.values.mobile}
                    className="form-control"
                    disabled={!formik.values.brokerId} 
                />
                {formik.touched.mobile && formik.errors.mobile && (
                    <div className='errorMsg'>{formik.errors.mobile}</div>
                )}
            </div>
            <div className='form-group'>
                <label className='label-control'>Email<span>*</span></label>
                <input
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    className="form-control"
                    disabled={!formik.values.brokerId} 
                />

                {formik.touched.email && formik.errors.email && (
                    <div className='errorMsg'>{formik.errors.email}</div>
                )}
            </div>
            <div className='form-group'>
                <label className='label-control'>Client Id<span>*</span></label>
                <input
                    name="appId"
                    onChange={formik.handleChange}
                    value={formik.values.appId}
                    className="form-control"
                    disabled={!formik.values.brokerId} 
                />
                {formik.touched.appId && formik.errors.appId && (
                    <div className='errorMsg'>{formik.errors.appId}</div>
                )}
            </div>
            <div className='form-group'>
                <label className='label-control'>App Key<span>*</span></label>
                <input
                    name="appKey"
                    onChange={formik.handleChange}
                    value={formik.values.appKey}
                    className="form-control"
                    disabled={!formik.values.brokerId} 
                />
                {formik.touched.appKey && formik.errors.appKey && (
                    <div className='errorMsg'>{formik.errors.appKey}</div>
                )}
            </div>
            <button type="submit" className='btn btn-primary mb-3' onClick={formik.handleSubmit}>Sign In</button>
        </>
    );
}

export default BrokerLogin;