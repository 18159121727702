import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import fetcher from '../../utils/fetcher';

const HomeWork = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const res = await fetcher.get('/admin-api/homework');
            if (res.status === 200) {
                setRows([
                    { name: 'Crude Oil', data: res.response.crud_oil },
                    { name: 'Dow Jones', data: res.response.dow_jones },
                    { name: 'Hang Seng', data: res.response.hang_seng },
                    { name: 'INR', data: res.response.inr },
                    { name: 'SGX', data: res.response.sgx }
                ]);
            } else {
                console.error('Error fetching data:', res.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 180000); // 3 minutes
        return () => clearInterval(interval);
    }, []);

    if (!rows.length && loading) {
        return <div>Loading...</div>;
    }

    return (
        <Paper>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Symbol</TableCell>
                        <TableCell>Current</TableCell>
                        <TableCell>Change(%)</TableCell>
                        <TableCell>Open</TableCell>
                        <TableCell>Prev. Close</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.data?.current_price}</TableCell>
                            <TableCell>{row.data?.change_percentage}</TableCell>
                            <TableCell>{row.data?.open}</TableCell>
                            <TableCell>{row.data?.previous_close}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

export default HomeWork;