import React, { useState, useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const TradingChart = ({ currentPrice }) => {
  const [trades] = useState([{ id: 1, buyPrice: 93, stopLoss: 88, takeProfit: 98 }]);
  const [prices, setPrices] = useState([92, 92, 91, 90, 90, 89, 88, 87, 88, 87, 88, 86, 87, 88, 89, 90, 90, 91, 92, 93, 95, 98, 94]);
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const lineSeriesRef = useRef(null);

  useEffect(() => {
    if (!chartContainerRef.current) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 400,
    });

    const lineSeries = chart.addLineSeries({
      color: '#0000FF',
      lineWidth: 2,
    });

    chartRef.current = chart;
    lineSeriesRef.current = lineSeries;

    const timeValues = Array.from({ length: prices.length }, (_, i) => i + 1);
    lineSeries.setData(timeValues.map((time, index) => ({ time, value: prices[index] })));

    const { buyPrice, stopLoss, takeProfit } = trades[0];

    const addHorizontalLine = (value, color) => {
      const line = chart.addLineSeries({ color, lineWidth: 1 });
      line.setData([{ time: 0, value }, { time: prices.length, value }]);
    };

    addHorizontalLine(buyPrice, 'orange');
    addHorizontalLine(stopLoss, 'red');
    addHorizontalLine(takeProfit, 'green');

    return () => {
      chart.remove();
    };
  }, [trades, prices]);

  useEffect(() => {
    if (currentPrice !== undefined && currentPrice !== 0) {
      setPrices((prevPrices) => {
        const updatedPrices = [...prevPrices, currentPrice].slice(-50); // Keep the last 50 prices
        const timeValues = Array.from({ length: updatedPrices.length }, (_, i) => i + 1);

        if (lineSeriesRef.current) {
          lineSeriesRef.current.setData(
            timeValues.map((time, index) => ({
              time,
              value: updatedPrices[index],
            }))
          );
        }

        return updatedPrices;
      });
    }
  }, [currentPrice]);

  return <div id="chart" ref={chartContainerRef} style={{ width: '100%', height: '400px' }}></div>;
};

export default TradingChart;
