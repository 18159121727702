import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./Checkout.module.scss";
import fetcher from "../../utils/fetcher";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe("pk_test_51OExgAAjBgx39L4vjxNOqTgvF4keW2VjQ8HVLZsJss9OwgKCG0tqthNhHlge6NzQ1UCr795tBO4giFY4SSLk777x00JHK1cXOf");

export default function Checkout() {
  const [clientSecret, setClientSecret] = useState('');

  const handlePaymentIntent = async () => {
    try {
      const data = await fetcher.post(`/payment/create-intent`, { productId: 1 });
      setClientSecret(data.clientSecret)
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    handlePaymentIntent();
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="checkout-form">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}