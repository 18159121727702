import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import fetcher from '../../utils/fetcher';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isAuthenticated } from '../../utils/authGuard';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import style from './Login.module.scss';
import { AUTHORITY, PageUrls } from "../../utils/constants";
import SnackBar from '../../components/SnackBar';
import { userAuthority } from '../../utils/authGuard';

const Login = () => {
  const navigate = useNavigate();
  const isAuthentic = isAuthenticated();
  const [showPassword, setShowPassword] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);  // new state for OTP input
  const [otp, setOtp] = useState('');  // new state for OTP value

  const [snackbar, setSnackbar] = useState({
    show: false,
    status: "",
    message: "",
  });
  const toggleSnackbar = (value) => {
    setSnackbar(value);
  };

  const [formData, setFormData] = useState({
    mobile: '',
    password: ''
  });

  const validationSchema = Yup.object().shape({
    mobile: Yup.string()
      .required('Mobile is required'),
    password: Yup.string()
      .required('Password is required')
  });

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = async (values) => {
    console.log('submitting');
    try {
      const res = await fetcher.post('/user-api/user-login', values);
      if (res?.status === 200) {
        setShowOtpInput(true);
        setSnackbar({
          show: true,
          status: 'success',
          message: 'OTP sent to your mobile number'
        });
      } else {
        setSnackbar({
          show: true,
          status: 'error',
          message: res?.message
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOtpSubmit = async () => {
    try {
      const res = await fetcher.post('/user-api/verify-otp', {
        mobile: formik.values.mobile,
        otp: otp
      });
      setSnackbar({
        show: true,
        status: res?.status === 200 ? 'success' : 'error',
        message: res?.status === 200 ? 'Login successfully' : res?.message
      });
      if (res?.status === 200) {
        localStorage.setItem('auth', JSON.stringify(res.response));
        navigateTo();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const navigateTo = () => {
    if(userAuthority().includes(AUTHORITY.ADMIN)){
      navigate(PageUrls.HOME_WORK);
    } else {
      navigate(PageUrls.BROKER_LOGIN);
    }
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (isAuthentic) {
      navigateTo();
    }
  }, []);

  return (
    <>
      <SnackBar {...snackbar} onClose={toggleSnackbar} />
      <div className={style.loginWrapper}>
        <div className={style.loginContainer}>
          <div className='row no-gutters'>
            <div className='col-md-6'>
              <div className={style.loginForm}>
                <h1>Sign In</h1>
                {!showOtpInput ? (
                  <>
                    <div className='form-group'>
                      <label className='label-control'>Mobile no<span>*</span></label>
                      <input
                        name="mobile"
                        onChange={formik.handleChange}
                        value={formik.values.mobile}
                        type="text"
                        className="form-control"
                      />
                      {formik.touched.mobile && formik.errors.mobile && (
                        <div className='errorMsg'>{formik.errors.mobile}</div>
                      )}
                    </div>
                    <div className='form-group'>
                      <label className='label-control'>Password<span>*</span></label>
                      <div className={style.passwrordField}>
                        <input
                          name="password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          type={showPassword ? 'text' : 'password'}
                          className="form-control"
                        />
                        <Button
                          type="button"
                          variant="text"
                          className={style.viewBtn}
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </Button>
                      </div>
                      {formik.touched.password && formik.errors.password && (
                        <div className='errorMsg'>{formik.errors.password}</div>
                      )}
                    </div>
                    <button type="submit" className='btn btn-primary mb-3' onClick={formik.handleSubmit}>Sign In</button>
                    <div className={style.forgotPassword}>
                      <a href="#">Forgot password ?</a>
                    </div>
                  </>
                ) : (
                  <div className='form-group'>
                    <label className='label-control'>Enter OTP<span>*</span></label>
                    <input
                      name="otp"
                      onChange={handleOtpChange}
                      value={otp}
                      type="text"
                      className="form-control"
                    />
                    <button type="button" className='btn btn-primary mb-3' onClick={handleOtpSubmit}>Verify OTP</button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
