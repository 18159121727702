import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import fetcher from '../../utils/fetcher';
import SnackBar from '../../components/SnackBar';

const BrokerLoginSuccess = () => {
  const { brokerId } = useParams();
  const location = useLocation();
  const [snackbar, setSnackbar] = useState({
    show: false,
    status: "",
    message: "",
  });

  const toggleSnackbar = (value) => {
    setSnackbar(value);
  };

  const handleAccessToken = async (authCode, brokerId) => {
    try {
        const res = await fetcher.post(`/user-api/get-access-token`, {
            auth_code: authCode,
            broker_id: brokerId
        });
        setSnackbar({
          show: true,
          status: res?.status === 200 ? 'success' : 'error',
          message: res?.status === 200 ? 'Access token saved successfully.' : res?.message
        });
    } catch (err) {
        console.log(err);
    }
}

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get('s');
    const code = searchParams.get('code');
    const authCode = searchParams.get('auth_code');
    const state = searchParams.get('state');

    console.log('brokerId', brokerId);
    console.log('Status:', status);
    console.log('Code:', code);
    console.log('Auth Code:', authCode);
    console.log('State:', state);

    if (authCode) {
        handleAccessToken(authCode, brokerId);
    }
  }, []);

  return (
    <div>
      {/* <h1>Broker Login Success</h1> */}
      <SnackBar {...snackbar} onClose={toggleSnackbar} />
      <p>Broker Id: {brokerId}</p>
      <p>Status: {new URLSearchParams(location.search).get('s')}</p>
      <p>Code: {new URLSearchParams(location.search).get('code')}</p>
      <p>Auth Code: {new URLSearchParams(location.search).get('auth_code')}</p>
      <p>State: {new URLSearchParams(location.search).get('state')}</p>
    </div>
  );
};

export default BrokerLoginSuccess;
