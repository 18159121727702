import React, { useEffect, useState } from "react";
import { Container, Paper, Table, TableHead, TableRow, TableCell, TableBody, Tabs, Tab, Box, CircularProgress, Typography, Button } from '@mui/material';
import fetcher from "../../utils/fetcher";

const UserTrades = () => {
  const [data, setData] = useState({ holding_stocks: [], closed_trades: [] });
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const fetchData = async () => {
    try {
      const res = await fetcher.get(`/admin-api/user-holdings`);
      setData(res.response);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const getStrategyNameById = (strategyId) => {
    const strategy = data.strategies.find(s => s.strategy_id === strategyId);
    return strategy ? strategy.name : 'Unknown Strategy';
  };

  const handleSellOpenTrades = async () => {
    const res = await fetcher.post('/admin-api/sell-user-holdings', {});
    console.log(res);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  const openTradesTable = (rows) => (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Strategy</TableCell>
            <TableCell>Symbol</TableCell>
            <TableCell>User Id</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Buy</TableCell>
            <TableCell>Broker Id</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? (
            rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{getStrategyNameById(row.strategy_id)}</TableCell>
                <TableCell>{row.symbol}</TableCell>
                <TableCell>{row.user_id}</TableCell>
                <TableCell>{row.quantity}</TableCell>
                <TableCell>{row.avg_buy_price}</TableCell>
                <TableCell>{row.broker_id}</TableCell>
                <TableCell>{row.created_date}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7} align="center">No trade found.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      
      {rows.length > 0 && (
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={handleSellOpenTrades}
          style={{ marginTop: '16px' }}
        >
          SELL HOLDINGS
        </Button>
      )}
    </div>
  );

  const closeTradesTable = (rows) => (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Strategy</TableCell>
          <TableCell>Symbol</TableCell>
          <TableCell>User Id</TableCell>
          <TableCell>Quantity</TableCell>
          <TableCell>Buy</TableCell>
          <TableCell>Sell</TableCell>
          <TableCell>Profit/Loss</TableCell>
          <TableCell>Broker Id</TableCell>
          <TableCell>Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.length > 0 ? (
          rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{getStrategyNameById(row.strategy_id)}</TableCell>
              <TableCell>{row.symbol}</TableCell>
              <TableCell>{row.user_id}</TableCell>
              <TableCell>{row.quantity}</TableCell>
              <TableCell>{row.avg_buy_price}</TableCell>
              <TableCell>{row.avg_sell_price}</TableCell>
              <TableCell>{row.profit_loss}</TableCell>
              <TableCell>{row.broker_id}</TableCell>
              <TableCell>{row.created_date}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={9} align="center">No trade found.</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );

  return (
    <Container>
      <Paper>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab label="Open Trades" />
          <Tab label="Closed Trades" />
        </Tabs>
        <Box sx={{ p: 3 }}>
          {selectedTab === 0 && openTradesTable(data.holding_stocks)}
          {selectedTab === 1 && closeTradesTable(data.closed_trades)}
        </Box>
      </Paper>
    </Container>
  );
};

export default UserTrades;
