import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import TradingChart from '../shared/Chart1';

const socket = io(process.env.REACT_APP_API_BASE_URL);

function StockLivePrice() {
  const [stockData, setStockData] = useState({ stock: '', price: 0 });

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    socket.on('stock_data', (data) => {
      console.log('Stock data received:', data);
      setStockData(data);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
    });

    return () => {
      socket.off('connect');
      socket.off('stock_data');
      socket.off('disconnect');
      socket.disconnect();
    };
  }, []);

  return (
    <div className="App">
      <h1>Live Stock Data</h1>
      <p>Stock: {stockData.stock}</p>
      <p>Price: ${stockData.price}</p>
      <TradingChart currentPrice={stockData.price} />
    </div>
  );
}

export default StockLivePrice;
