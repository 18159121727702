import React, { useState } from 'react'; 
import style from './Header.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
 
const Header = ({mWebClick}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('auth');
    navigate('/login');
  };



  return(
    <>
      <header className={style.header}>
        <div className='d-lg-none'>
          <IconButton onClick={mWebClick}>
            <MenuIcon />
          </IconButton>
        </div>

        <div className={style.headerRight}>
          <IconButton aria-label="account" onClick={handleClick}>
            <AccountCircle />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem>
              <Typography variant="body1">Wallet Balance: 0</Typography>
            </MenuItem>
            <MenuItem component={Link} to="/payment-checkout">Add Balance</MenuItem>
            <MenuItem onClick={handleClose}>Logout</MenuItem>
          </Menu>
        </div>
      </header> 
    </>
  )
}
  
export default Header;