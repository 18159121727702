import React, { useEffect, useState } from 'react';
import fetcher from "../../utils/fetcher";
import { Container, Table, TableBody, TableCell, TableHead, TableRow, Paper, CircularProgress, Select, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';

const OptionChain = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedInterval, setSelectedInterval] = useState(5);

    const fetchData = async (interval = 5) => {
        try {
            const res = await fetcher.get(`/admin-api/option-chain-data?interval=${interval}`);
            setData(res.response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        const interval = setInterval(() => {
            setLoading(true);
            fetchData();
        }, 300000); // 5 minutes

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    const handleIntervalChange = (event) => {
        setSelectedInterval(event.target.value);
        fetchData(event.target.value);
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (data.length === 0) {
        return <div>No records found</div>;
    }

    return (
        <Container>
            <Select value={selectedInterval} onChange={handleIntervalChange}>
                <MenuItem value={5}>5 Minutes</MenuItem>
                <MenuItem value={10}>10 Minutes</MenuItem>
                <MenuItem value={15}>15 Minutes</MenuItem>
                <MenuItem value={30}>30 Minutes</MenuItem>
            </Select>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Time</TableCell>
                            <TableCell>OI</TableCell>
                            <TableCell>OI Change</TableCell>
                            <TableCell>PCR</TableCell>
                            <TableCell>Pivot</TableCell>
                            <TableCell>Volume</TableCell>
                            <TableCell>CPR</TableCell>
                            <TableCell>Call LTP</TableCell>
                            <TableCell>Strike Price</TableCell>
                            <TableCell>OI</TableCell>
                            <TableCell>OI Change</TableCell>
                            <TableCell>PCR</TableCell>
                            <TableCell>Pivot</TableCell>
                            <TableCell>Volume</TableCell>
                            <TableCell>CPR</TableCell>
                            <TableCell>Put LTP</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.time}</TableCell>
                                <TableCell>{row.oi_ce}</TableCell>
                                <TableCell>{row.oi_change_ce}</TableCell>
                                <TableCell>{row.pcr_ce}</TableCell>
                                <TableCell>{row.pivot_ce}</TableCell>
                                <TableCell>{row.volume_ce}</TableCell>
                                <TableCell>{row.cpr_ce}</TableCell>
                                <TableCell>{row.ltp_ce}</TableCell>
                                <TableCell>
                                    <Link to={`/option-chain-indicator/${row.strike_price}`}>
                                        {row.strike_price}
                                    </Link>
                                </TableCell>
                                <TableCell>{row.oi_pe}</TableCell>
                                <TableCell>{row.oi_change_pe}</TableCell>
                                <TableCell>{row.pcr_pe}</TableCell>
                                <TableCell>{row.pivot_pe}</TableCell>
                                <TableCell>{row.volume_pe}</TableCell>
                                <TableCell>{row.cpr_pe}</TableCell>
                                <TableCell>{row.ltp_pe}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Container>
    );
};

export default OptionChain;